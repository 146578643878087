/* ./GlobalContext.js */

import React, { createContext } from "react"

const initialState = {
  wingdings: false,
}

const actions = {
  ENABLE_WINGDINGS: "ENABLE_WINGDINGS",
  DISABLE_WINGDINGS: "DISABLE_WINGDINGS",
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.ENABLE_WINGDINGS:
      console.log('enable')
      return { ...state, wingdings: true }
    case actions.DISABLE_WINGDINGS:
      console.log('disable')
      return { ...state, wingdings: false }
    default:
      return state
  }
}

const GlobalContext = createContext()

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const value = {
    wingdings: state.wingdings,
    enableWingdings: value => {
      dispatch({ type: actions.ENABLE_WINGDINGS })
    },
    disableWingdings: value => {
      dispatch({ type: actions.DISABLE_WINGDINGS })
    }
  }

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

// The exported GlobalContextProvider will only be used in our layout/index.js,
// while the general GlobalContext can be used by any Page or Component (with
// the help of useContext).

export { GlobalContextProvider as default, GlobalContext }