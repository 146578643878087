/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
// import Header from "./header_2024"
import "./css/layout.scss"
import "./css/layout_v2024.scss"

import GlobalContextProvider from "../pages/GlobalContext"

const Layout = ({ children, headerType, headerColor, isIndex, mainRef }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [wingdings, setWingdings] = useState(false)

  const prevScrollY = useRef(0)
  const [goingUp, setGoingUp] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY && goingUp) setGoingUp(false)
      if (prevScrollY.current > currentScrollY && !goingUp) setGoingUp(true)
      prevScrollY.current = currentScrollY
    }
    // window.addEventListener('scroll', handleScroll, { passive: true })
  }, [])

  return (
    <GlobalContextProvider>
      <Header 
        siteTitle={data.site.siteMetadata?.title || `Title`} 
        headerType={headerType}
        headerColor={headerColor} 
        isIndex={isIndex}
        className={goingUp ? 'hidden' : ''}
      />
      <div className="layout">
        <main ref={mainRef}>
          {children}
        </main>
      </div>
    </GlobalContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  // headerType: PropTypes.node.isRequired,
}

export default Layout
