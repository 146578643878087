import React, {useState, useContext} from "react"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"

import { useMediaQuery } from 'react-responsive'

import { GlobalContext } from "../pages/GlobalContext"

const Header = ({ siteTitle, headerType, headerColor, isIndex }) => {

  const isMobile = useMediaQuery({query: '(max-width : 480px)'})

  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false)

  const { enableWingdings, disableWingdings } = useContext(GlobalContext)

  return (
    <header 
      style={headerColor ? {backgroundColor: headerColor} : {}} 
    >
      {/* {
        headerType == 'filter' ?
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="myfilter">
              <feComponentTransfer in="SourceGraphic" result="recolored">
                  <feFuncR type="linear" slope="0" intercept="1"/>
              </feComponentTransfer>
              <feColorMatrix in="SourceGraphic" type="luminanceToAlpha" result="lumMask"/>
              <feComponentTransfer in="lumMask" result="lumMaskCeil">
                <feFuncA type="gamma" exponent="2"/>
              </feComponentTransfer>
              <feComposite operator="in" in="SourceGraphic" in2="lumMaskCeil" result="content"/>
              <feFlood floodColor="#bdccd4" result="COLOR" /> 
              <feComposite operator="over" in="content" in2="COLOR"/>   
            </filter>
          </defs>
        </svg>
        : 
        <div />
      } */}

      <div id="mobile-header-icon" onTouchEnd={() => { setIsMobileHeaderOpen(!isMobileHeaderOpen) }} />

      <div id="header-nav" className={isMobileHeaderOpen ? "open" : ""}>
        <ul>
          <li id="header-about"> 
            <TransitionLink to="/about"
              entry={{
                trigger: () => setIsMobileHeaderOpen(false)
              }}
            >
              <p> ABOUT </p> 
            </TransitionLink>
          </li>
          <li id="header-projects">
            <TransitionLink to="/other"
              entry={{
                trigger: () => setIsMobileHeaderOpen(false)
              }}
            >
              <p>OTHER</p>
           </TransitionLink>
          </li>
          <li id="header-home">
            {
              isMobile ?
                <a href="/">
                  <p> HOME </p> 
                </a>
              :
                <TransitionLink to="/"
                  entry={{
                    trigger: () => setIsMobileHeaderOpen(false)
                  }}
                >
                  <p> HOME </p> 
                </TransitionLink>
            }
          </li>
          {/* {
            isMobileHeaderOpen ? 
            <li>
              <TransitionLink to="/other"
                entry={{
                  trigger: () => setIsMobileHeaderOpen(false)
                }}
              >
                <p>OTHER</p>
              </TransitionLink>
            </li>
            :
            <div />
          } */}
        </ul>
      </div>

      {/* <div id="header-thoughts">
        <TransitionLink to="/other">
          <p> OTHER </p> 
        </TransitionLink>
      </div> */}
            
      {/* <div id="wingdings-button">
        <div>
          <p> 
            <span onClick={disableWingdings}> EN </span> 
              / 
            <span onClick={enableWingdings}> WINGDINGS </span> 
          </p>
        </div>
      </div> */}

    </header>
  ) 
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  setWingdings: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: `Dexter Callender III`,
}

export default Header
